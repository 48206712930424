import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CampaignCharSheetNav from './CampaignCharSheetNav';
import CampaignCharSheetMainStats from './CampaignCharSheetMainStats';
import CampaignCharSheetPowers from './CampaignCharSheetPowers';
import CampaignCharSheetInventory from './CampaignCharSheetInventory';
import CampaignCharSheetNotes from './CampaignCharSheetNotes';
import CampaignCharDetailPop from './popups/CampaignCharDetailPop';
import CampaignAddItemPopUp from './popups/CampaignAddItemPopUp';
import ConfirmItemUpdate from './popups/ConfirmItemUpdate';
import ConfirmItemEquip from './popups/ConfirmItemEquip';
import ConfirmRemoveChar from './popups/ConfirmRemoveChar';
import CharacterToolTip from './popups/CharacterToolTip';
import Loader from '../components/Loader.js';

class CampaignCharSheet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      character: null,
      characters: this.props.campaign.characters,
      charSheetSection: 'mainStats',
      isPoppedUp: false,
      isItemAddPoppedUp: false,
      detailPopUp: false,
      detailType: null,
      details: null,
      detailedPickup: null,
      optionToDrop: false,
      itemRequestType: null,
      updateItemConfirmation: false,
      requestedItem: null,
      openField: null,
      typedValue: "",
      equipButtonClicked: false,
      // loading: this.props.level !== 'dm' ? true : false,
      loading: false,
      file: null,
      preview: null,
      typedCharNotesValue: "",
      typedCampaignNotesValue: "",
      confirmRemovePop: false,
      requestedRemoveCharacter: null,
      toolTipPoppedUp: false,
      qualityName: null,
    };
    // this.getCharacter = this.getCharacter.bind(this);
    this.handleSectionClick = this.handleSectionClick.bind(this);
    this.handleSuccessClick = this.handleSuccessClick.bind(this);
    this.handleFailureClick = this.handleFailureClick.bind(this);
    this.submitSuccessFailAction = this.submitSuccessFailAction.bind(this);
    this.updateCharacter = this.updateCharacter.bind(this);
    this.handleItemAddPopUpClick = this.handleItemAddPopUpClick.bind(this);
    this.handleItemChange = this.handleItemChange.bind(this);
    this.handleDetailClick = this.handleDetailClick.bind(this);
    this.closeConfirmUpdateItemBox = this.closeConfirmUpdateItemBox.bind(this);
    this.closeDetailBox = this.closeDetailBox.bind(this);
    this.closeConfirmEquipBox = this.closeConfirmEquipBox.bind(this);
    this.updateItemRequest = this.updateItemRequest.bind(this);
    this.handleTypeableFieldClick = this.handleTypeableFieldClick.bind(this);
    this.handleTypedValueChange = this.handleTypedValueChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.selectCharacter = this.selectCharacter.bind(this);
    this.getSpecificCharacter = this.getSpecificCharacter.bind(this);
    this.saveValue = this.saveValue.bind(this);
    this.equipItemRequest = this.equipItemRequest.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.processEquippedStatus = this.processEquippedStatus.bind(this);
    this.handleLevelUpClick = this.handleLevelUpClick.bind(this);
    this.handlePreparation = this.handlePreparation.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.onImageSave = this.onImageSave.bind(this);
    this.resetImageFields = this.resetImageFields.bind(this);
    this.handleTypedCharNotesValueChange = this.handleTypedCharNotesValueChange.bind(this);
    this.handleTypedCampaignNotesValueChange = this.handleTypedCampaignNotesValueChange.bind(this);
    this.closeRemovePop = this.closeRemovePop.bind(this);
    this.handleRemoveCharacter = this.handleRemoveCharacter.bind(this);
    this.refetchCampaignChars = this.refetchCampaignChars.bind(this);
    this.handleToolTipClick = this.handleToolTipClick.bind(this);
    this.handleToolTipClose = this.handleToolTipClose.bind(this);
    this.handleQualityClick = this.handleQualityClick.bind(this);
  }

  componentDidMount() {
    // if (localStorage.getItem('toChar') === 'true') {
    //   localStorage.setItem('toChar', JSON.stringify(false));
    // }
    // if (this.props.level !== 'dm') {
    //   this.getCharacter();
    // }
  }

  // getCharacter() {
  //   fetch(`/api/v1/campaign/${this.props.campaign.id}/character`, {
  //     credentials: 'same-origin',
  //     method: 'GET',
  //     headers: { 'Content-Type':'application/json'}
  //   })
  //   .then(response =>
  //     response.json().then(body => ({
  //       body: body,
  //       status: response.status
  //     })
  //   ).then(res => {
  //     if(res.status === 200) {
  //       this.setState({
  //         character: res.body.character,
  //         typedCharNotesValue: res.body.character.notes,
  //         typedCampaignNotesValue: res.body.character.campaign_notes,
  //         loading: false,
  //       });
  //     } else {
  //       this.setState({
  //         loading: false,
  //       });
  //       // this.props.pop(res.body.msg, res.body.status);
  //     }
  //   }));
  // }

  selectCharacter(e, character) {
    e.preventDefault();
    if (e.target.localName === "i") {
      this.setState({
        confirmRemovePop: true,
        requestedRemoveCharacter: character.id
      });
    } else {
      this.setState((prevState) => {
        return {
          loading: true
        };
      });
      this.getSpecificCharacter(character);
    }
  }

  getSpecificCharacter(character) {
    fetch(`/api/v1/campaign/${this.props.campaign.id}/character/${character.id}`, {
      credentials: 'same-origin',
      method: 'GET',
      headers: { 'Content-Type':'application/json'}
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if(res.status === 200) {
        this.setState({
          character: res.body.character,
          typedCharNotesValue: res.body.character.notes,
          typedCampaignNotesValue: res.body.character.campaign_notes,
          loading: false,
        });
      } else {
        this.props.pop(res.body.msg, res.body.status);
      }
    }));
  }

  handleSectionClick(e, section) {
    this.setState({
      charSheetSection: section
    });
  }

  // cbStrClick(e) {e.preventDefault(); handleSavingThrowProficiencyChange('Str');}
  // cbDexClick(e) {e.preventDefault(); handleSavingThrowProficiencyChange('Dex');}
  // cbConClick(e) {e.preventDefault(); handleSavingThrowProficiencyChange('Con');}
  // cbIntClick(e) {e.preventDefault(); handleSavingThrowProficiencyChange('Int');}
  // cbWisClick(e) {e.preventDefault(); handleSavingThrowProficiencyChange('Wis');}
  // cbChaClick(e) {e.preventDefault(); handleSavingThrowProficiencyChange('Cha');}

  handleSuccessClick(e) {
    e.preventDefault();
    let tally = this.state.character.successes === 3 ? 0 : this.state.character.successes + 1;
    this.submitSuccessFailAction(true, tally)
  }
  handleFailureClick(e) {
    e.preventDefault();
    let tally = this.state.character.failures === 3 ? 0 : this.state.character.failures + 1;
    this.submitSuccessFailAction(false, tally)
  }

  submitSuccessFailAction(isSuccess, tally) {
    let formPayload = new FormData();
    formPayload.append('type', "failSuccess");
    formPayload.append('isSuccess', isSuccess);
    formPayload.append('tally', tally);
    this.updateCharacter(formPayload);
  }

  updateCharacter(formPayload) {
    fetch(`/api/v1/edit_character/${this.state.character.id}`, {
      method: 'PUT',
      body: formPayload,
      credentials: 'same-origin'
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      // this.props.pop(res.body.msg, res.body.status);
      if(res.status === 200) {
        this.setState({ character: res.body.character });
      }
    }));
  }

  // handlePopUpClick(e, isOpening) {
  //   e.preventDefault();
  //   this.setState({
  //     isPoppedUp: isOpening
  //   });
  // }

  handleItemAddPopUpClick(e, isOpening) {
    e.preventDefault();
    this.setState({
      isItemAddPoppedUp: isOpening
    });
  }

  handleItemChange(e, item, quantity, actionType, closeBox=true) {
    e.preventDefault();
    let formPayload = new FormData();
    formPayload.append('item', item.id);
    formPayload.append('quantity', quantity);
    formPayload.append('type', actionType);
    this.processItemChange(formPayload, item, quantity, closeBox);
    if (closeBox) {
      this.closeDetailBox(null);
    }
  }

  processItemChange(formPayload, item, quantity, isClosingBox=true) {
    fetch(`/api/v1/campaign/${this.props.campaign.id}/character/${this.state.character.id}/change_item`, {
      credentials: 'same-origin',
      method: 'PUT',
      body: formPayload
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if(res.status === 200) {
        this.setState((prevState) => {
          let char = Object.assign({}, prevState.character);
          char.inventories[0] = res.body.inventory
          let detailedPickup = Object.assign({}, prevState.detailedPickup);
          if (!isClosingBox) {
            let inv = res.body.inventory;
            let totalInv = inv.gear.concat(
              inv.armor, inv.mounts, inv.weapons, inv.tools
            );
            if (totalInv.some(pickup =>
              pickup.item.id === item.id
            )) {
              detailedPickup = totalInv.filter(pickup =>
                pickup.item.id === item.id
              )[0];
            } else {
              detailedPickup = null;
            }
          }
          return { char, detailedPickup };
        });
      } else {
        this.props.pop(res.body.msg, res.body.status);
      }
    }));
  }

  handleDetailClick(e, id, source, optionToDrop=false, pickup=null) {
    e.preventDefault();
    if (pickup !== null) {
      this.setState((prevState) => {
        return {
          detailedPickup: pickup
        };
      });
    }
    let formPayload = new FormData();
    formPayload.append('type', source);
    formPayload.append('identifier', id);
    fetch(`/api/v1/campaign/character/detail_click`, {
      credentials: 'same-origin',
      method: 'POST',
      body: formPayload
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if(res.status === 200) {
        this.setState({
          detailPopUp: true,
          detailType: source,
          details: res.body.details,
          optionToDrop: optionToDrop
        });
      }
    }));
  }

  // dropItemRequest(e, item) {
  //   e.preventDefault();
  //   this.setState({
  //     requestedItem: item,
  //     updateItemConfirmation: true
  //   });
  // }

  updateItemRequest(e, item, requestType) {
    e.preventDefault();
    this.setState({
      requestedItem: item,
      itemRequestType: requestType,
      updateItemConfirmation: true
    });
  }

  closeDetailBox(e) {
    if (e !== null) {
      e.preventDefault();
    }
    this.setState({
      detailPopUp: false,
      detailType: null,
      details: null,
      detailedPickup: null,
      optionToDrop: false,
      updateItemConfirmation: false,
      isItemAddPoppedUp: null,
      requestedItem: null,
      itemRequestType: null
    });
  }

  closeConfirmUpdateItemBox(e) {
    if (e !== null) {
      e.preventDefault();
    }
    this.setState({
      updateItemConfirmation: false,
      itemRequestType: null,
      requestedItem: null
    });
  }

  handleTypeableFieldClick(e, type) {
    e.preventDefault();
    if (e.target.localName !== "input") {
      if (this.state.openField !== type) {
        this.setState((prevState) => {
          return {
            openField: type,
            typedValue: ""
          }
        });
      } else {
        this.setState({
          openField: null,
          typedValue: ""
        })
      }
    }
  }

  handleTypedValueChange(event) {
    event.preventDefault();
    const val = event.target.value;
    this.setState((prevState) => {
      return {
        typedValue: val
      };
    });
  }

  handleKeyPress(e) {
    e.preventDefault();
    if (e.key === 'Enter' && this.state.typedValue) {
      this.saveValue(this.state.openField, this.state.typedValue);
    } else {
      this.setState({
        openField: null
      })
    }
  }

  saveValue(field, typedValue) {
    let formPayload = new FormData();
    formPayload.append('char_field', field);
    formPayload.append('value', typedValue);
    fetch(`/api/v1/campaign/${this.props.campaign.id}/character/${this.state.character.id}`, {
      method: 'PUT',
      body: formPayload,
      credentials: 'same-origin'
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if(res.status === 200) {
        this.setState((prevState) => {
          let char = Object.assign({}, prevState.character);
          if (res.body.source === 'inventory') {
            char.inventories[0] = res.body.character.inventories[0]
          } else if (res.body.source === 'main') {
            char[res.body.field] = res.body.character[res.body.field]
          }
          return { character: char };
        });
      }
    }));
    this.setState((prevState) => {
      return {
        typedValue: "",
        openField: null
      };
    });
  }

  closeConfirmEquipBox(e) {
    if (e !== null) {
      e.preventDefault();
    }
    this.setState({
      equipButtonClicked: false,
      requestedItem: null
    });
  }

  handleGoBack(e) {
    if (e !== null) {
      e.preventDefault();
    }
    this.setState({
      character: null,
    });
  }

  equipItemRequest(e, item, toBeEquipped, section) {
    e.preventDefault();
    if (toBeEquipped) {
      this.setState({
        equipButtonClicked: true,
        requestedItem: item
      });
    } else {
      this.processEquippedStatus(null, item, false, section);
    }
  }

  processEquippedStatus(e, item, toBeEquipped, section) {
    if (e !== null) {
      e.preventDefault();
    }
    let formPayload = new FormData();
    formPayload.append('item', item.id);
    formPayload.append('toBeEquipped', toBeEquipped);
    formPayload.append('selectedSection', section);
    fetch(`/api/v1/campaign/character/${this.state.character.id}/update_char`, {
      method: 'PUT',
      body: formPayload,
      credentials: 'same-origin'
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if(res.status === 200) {
        this.setState((prevState) => {
          let char = Object.assign({}, prevState.character);
          char.inventories[0] = res.body.inventory
          return { char };
        });
      }
    }));
    this.setState((prevState) => {
      return {
        equipButtonClicked: false,
        requestedItem: null,
        detailPopUp: false,
        detailType: null,
        details: null,
        detailedPickup: null,
        optionToDrop: false,
        updateItemConfirmation: false,
        isItemAddPoppedUp: null,
        itemRequestType: null,
      };
    });
  }

  handlePreparation(e, powerId) {
    e.preventDefault();
    let formPayload = new FormData();
    formPayload.append('spell_id', powerId);
    fetch(`/api/v1/campaign/character/${this.state.character.id}/prepare_spell`, {
      method: 'PUT',
      body: formPayload,
      credentials: 'same-origin'
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if(res.status === 200) {
        // this.setState((prevState) => {
        //   let char = Object.assign({}, prevState.character);
        //   char.prepared_spells = res.body.prepared_spells
        //   return { char };
        // });
        this.setState({
            character: {
                ...this.state.character,
                "prepared_spells" : res.body.prepared_spells,
                "spell" : res.body.spells,
                "expanded_spells" : res.body.expanded_spells
            }
        });
      }
    }));
  }

  handleLevelUpClick(e) {
    e.preventDefault();
    // localStorage.setItem('campaign.id', JSON.stringify(this.props.campaign.id));
    // localStorage.setItem('toChar', JSON.stringify(true));
    this.props.history.push(`/character/creation/${this.state.character.id}`);
  }

  handleImageChange(e) {
    this.setState({file: e.target.files[0]});
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      this.setState({preview: [reader.result]});
    }.bind(this);
  }

  onImageSave(e) {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        loading: true
      };
    });
    let formPayload = new FormData();
    formPayload.append('file', this.state.file);
    formPayload.append('genre', 'characters');
    formPayload.append('category', 'character');
    formPayload.append('charNotes', this.state.typedCharNotesValue);
    formPayload.append('campaignNotes', this.state.typedCampaignNotesValue);
    fetch(`/api/v1/campaign/character/${this.state.character.id}/update_char_notes`, {
      credentials: 'same-origin',
      method: 'PUT',
      body: formPayload
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if(res.status === 200) {
        this.setState((prevState) => {
          let char = Object.assign({}, prevState.character);
          char.notes = res.body.character.notes
          char.campaign_notes = res.body.character.campaign_notes
          char.photo = res.body.character.photo
          return {
            character: char,
            file: null,
            preview: null,
            loading: false
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            loading: false
          };
        });
        this.props.pop(res.body.msg, res.body.status);
      }
    }));
  }

  resetImageFields(e) {
    e.preventDefault();
    this.setState((preState) => {
      return {
        file: null,
        preview: null,
      };
    });
    // this.displayUserProfile();
  }

  handleTypedCharNotesValueChange(e) {
    e.preventDefault();
    const val = e.target.value;
    if (JSON.stringify(val.replaceAll('\r','')).length - 2 <= 3000) {
      this.setState((prevState) => {
        return {
          typedCharNotesValue: val
        };
      });
    }
  }

  handleTypedCampaignNotesValueChange(e) {
    e.preventDefault();
    const val = e.target.value;
    if (JSON.stringify(val.replaceAll('\r','')).length - 2 <= 1000) {
      this.setState((prevState) => {
        return {
          typedCampaignNotesValue: val
        };
      });
    }
  }

  // removeCharacterFromCampaign(e, character) {
  //   e.preventDefault();
  //   this.setState((prevState) => {
  //     return {
  //       confirmRemovePop: true,
  //       requestedRemoveCharacter: character.id
  //     };
  //   });
  // }

  closeRemovePop(e) {
    e.preventDefault();
    this.setState({
      confirmRemovePop: false
    });
  }

  handleRemoveCharacter(e, requestedCharacter) {
    let formPayload = new FormData();
    formPayload.append('campaign', this.props.campaign.id);
    formPayload.append('character', requestedCharacter);
    fetch('/api/v1/campaign/remove_character', {
      credentials: 'same-origin',
      method: 'PUT',
      body: formPayload
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      this.props.pop(res.body.msg, res.body.status);
      if(res.status === 200) {
        this.refetchCampaignChars();
      }
    }));
  }

  refetchCampaignChars() {
    fetch(`/api/v1/campaign/${this.props.campaign.id}`, {
      credentials: 'same-origin',
      method: 'GET',
    })
    .then(response =>
      response.json().then(body => ({
        body: body,
        status: response.status
      })
    ).then(res => {
      if(res.status === 200) {
        this.setState((prevState) => {
          return { characters: res.body.characters, confirmRemovePop: false };
        });
      }
    }));
  }

  handleToolTipClick(e=null) {
    if (e !== null) {
      e.preventDefault();
    }
    this.setState({
      toolTipPoppedUp: true,
    });
  }

  handleToolTipClose(e=null) {
    if (e !== null) {
      e.preventDefault();
    }
    this.setState({
      toolTipPoppedUp: false,
    });
  }

  handleQualityClick(e, quality, name) {
    e.preventDefault();
    this.setState({
      detailPopUp: true,
      detailType: 'quality',
      qualityName: name,
      details: quality,
    });
  }

  render() {
    const sections = [
      {'code':'mainStats', 'name':'Main Stats', 'symbol':'fa-user'},
      // {'code':'combat', 'name':'Combat', 'symbol':'fa-shield'},
      {'code':'powers', 'name':'Powers', 'symbol':'fa-book'},
      {'code':'inventory', 'name':'Inventory', 'symbol':'fa-sack-dollar'},
      {'code':'notes', 'name':'Notes', 'symbol':'fa-id-card'},
    ];
    //other goods ones such as 'fa-wand-sparkles'

    let nav;
    let campaignSection;
    let detailPop;
    let itemPop;
    let updateItemConfirmation;
    let equipItemConfirm;
    let confirmRemoveChar;
    let players;
    let playersSection;
    let toolTipPopUp;

    // if(this.props.level !== 'dm' || this.state.character) {
    if(this.state.character) {
      nav = <CampaignCharSheetNav
                  sections={sections}
                  selectedSection={this.state.charSheetSection}
                  handleSectionClick={this.handleSectionClick}
                />;
      if (this.state.charSheetSection === 'inventory') {
        campaignSection = <CampaignCharSheetInventory
                            character={this.state.character}
                            handleDetailClick={this.handleDetailClick}
                            handleCurrencyClick={this.handleTypeableFieldClick}
                            handleItemAddPopUpClick={this.handleItemAddPopUpClick}
                            handleTypedValueChange={this.handleTypedValueChange}
                            handleKeyPress={this.handleKeyPress}
                            openField={this.state.openField}
                            typedValue={this.state.typedValue}
                          />
      } else if (this.state.charSheetSection === 'powers') {
        campaignSection = <CampaignCharSheetPowers
                            character={this.state.character}
                            handleDetailClick={this.handleDetailClick}
                            handlePreparation={this.handlePreparation}
                          />
      } else if (this.state.charSheetSection === 'notes') {
        campaignSection = <CampaignCharSheetNotes
                            handleChange={this.handleImageChange}
                            handleTypedCharNotesValueChange={this.handleTypedCharNotesValueChange}
                            handleTypedCampaignNotesValueChange={this.handleTypedCampaignNotesValueChange}
                            onSave={this.onImageSave}
                            resetFields={this.resetImageFields}
                            file={this.state.file}
                            preview={this.state.preview}
                            typedCharNotesValue={this.state.typedCharNotesValue}
                            typedCampaignNotesValue={this.state.typedCampaignNotesValue}
                            character={this.state.character}
                          />
      } else {
      // section === 'mainStats' ... default to this
        campaignSection = <CampaignCharSheetMainStats
                            currentUser={this.props.currentUser}
                            character={this.state.character}
                            level={this.props.level}
                            openField={this.state.openField}
                            typedValue={this.state.typedValue}
                            handleHPClick={this.handleTypeableFieldClick}
                            handleQualityClick={this.handleQualityClick}
                            handleTypedValueChange={this.handleTypedValueChange}
                            handleKeyPress={this.handleKeyPress}
                            handleLevelUpClick={this.handleLevelUpClick}
                            handleSuccessClick={this.handleSuccessClick}
                            handleFailureClick={this.handleFailureClick}
                            handleDetailClick={this.handleDetailClick}
                            handleGoBack={this.handleGoBack}
                            handleToolTipClick={this.handleToolTipClick}
                          />;
      }
      if (this.state.detailPopUp) {
        detailPop = <CampaignCharDetailPop
                      detailType={this.state.detailType}
                      name={this.state.qualityName}
                      details={this.state.details}
                      detailedPickup={this.state.detailedPickup}
                      closePop={this.closeDetailBox}
                      optionToDrop={this.state.optionToDrop}
                      updateItemRequest={this.updateItemRequest}
                      equipItemRequest={this.equipItemRequest}
                      handleItemChange={this.handleItemChange}
                    />
      }
      if (this.state.isItemAddPoppedUp) {
        itemPop = <CampaignAddItemPopUp
                      closePop={this.closeDetailBox}
                      onSubmit={this.handleItemChange}
                    />
      }
      if (this.state.updateItemConfirmation) {
          updateItemConfirmation = <ConfirmItemUpdate
                            itemRequestType={this.state.itemRequestType}
                            closePop={this.closeConfirmUpdateItemBox}
                            onSubmit={this.handleItemChange}
                            requestedItem={this.state.requestedItem}
                          />
      }
      if (this.state.equipButtonClicked) {
        equipItemConfirm = <ConfirmItemEquip
                            character={this.state.character}
                            closePop={this.closeConfirmEquipBox}
                            onSubmit={this.processEquippedStatus}
                            requestedItem={this.state.requestedItem}
                          />
      }
      if (this.state.toolTipPoppedUp) {
        toolTipPopUp = <CharacterToolTip
                          closePop={this.handleToolTipClose}
                        />
      }
    } else if (
      this.props.level !== 'viewer' &&
      (
        this.props.campaign && this.props.users && this.state.characters.length > 0 && (
          this.state.characters.some(character =>
            character.creator_id === this.props.currentUser.id
          ) ||
          this.props.level === 'dm'
        )
      )
    ) {
      let users = this.props.users;
      players = this.state.characters.map((character, index) => {
        let user = users.filter(u =>
          u.id === character.creator_id
        )[0]
        if (this.props.level === 'dm' || this.props.currentUser.id === user.id) {
          return <div
                    key={index}
                    className="campaign-char-sheet-character-row"
                    onClick={((e) => this.selectCharacter(e, character))}
                  >
                    {`${character.name}, ${character.archrace} ${character.classification} | level ${character.level} | (${user.display_name})`}
                    <div className="remove-char-from-campaign-button-container">
                      <div className="remove-char-from-campaign-button"><i className="fa-solid fa-trash-can campaign-char-sheet-remove-character-icon"></i></div>
                    </div>
                 </div>;
        } else {
          return null;
        }
      });
      playersSection = <div className="campaign-char-sheet-characters-wrapper">
                         <div className="campaign-char-sheet-characters">
                           <div className="campaign-char-sheet-characters-heading">
                             Choose a Character Sheet to View
                           </div>
                           <div className="campaign-char-sheet-character-rows">
                             {players}
                           </div>
                         </div>
                       </div>
    } else if (
      this.props.campaign &&
      this.props.users &&
      this.state.characters.length === 0 &&
      this.props.level === 'dm'
    ) {
      playersSection = <div className="campaign-char-sheet-characters-wrapper">
                         <div className="campaign-char-sheet-characters-alt">
                           <div className="campaign-char-sheet-characters-heading-alt">
                             There are no characters in this campaign. As a DM, encourage members of your campaign to import their created character(s). You may also import characters of your own into this campaign.
                           </div>
                         </div>
                       </div>
    } else if (this.props.level !== 'viewer') {
      playersSection = <div className="campaign-char-sheet-characters-wrapper">
                         <div className="campaign-char-sheet-characters-alt">
                           <div className="campaign-char-sheet-characters-heading-alt">
                             You don't have any characters in this campaign. Import one of your created characters into this campaign.
                           </div>
                         </div>
                       </div>
    } else {
      playersSection = <div className="campaign-char-sheet-characters-wrapper">
                         <div className="campaign-char-sheet-characters-alt">
                           <div className="campaign-char-sheet-characters-heading-alt">
                             Only members of this campaign can access this information.
                           </div>
                         </div>
                       </div>
    }

    if (this.state.confirmRemovePop) {
      confirmRemoveChar = <ConfirmRemoveChar
                            requestedRemoveCharacter={this.state.requestedRemoveCharacter}
                            closePop={this.closeRemovePop}
                            onSubmit={this.handleRemoveCharacter}
                          />
    }

    if (this.state.loading !== false) return <Loader/>;
    const loader = this.state.loading ? <i className="fa fa-cog fa-spin" /> : null;

    return(
      <div className="campaign-char-container">
        {loader}
        {nav}
        {campaignSection}
        {detailPop}
        {itemPop}
        {toolTipPopUp}
        {updateItemConfirmation}
        {equipItemConfirm}
        {confirmRemoveChar}
        {playersSection}
      </div>
    )
  }
}

export default withRouter(CampaignCharSheet)
