import React from 'react';

const CampaignCharSheetMainStats = props => {

  // string initializations
  let name, race, classification, background, alignment,
      level, exp, curHp, tempHp, maxHp, hitDice, speed, initiative, ac,
      strMod, strScore, dexMod, dexScore, conMod, conScore,
      intMod, intScore, wisMod, wisScore, chaMod, chaScore,
      profBonus, inspiration, bardicInspirations, //passPerc, passIns, passInv,
      statStr, statDex, statCon, statInt, statWis, statCha,
      statAcr, statAni, statArc, statAth, statDec, statHis,
      statIns, statInti, statInv, statMed, statNat, statPerc,
      statPerf, statPers, statRel, statSle, statSte, statSur,
      renderedBonusFields, renderedPersonality, renderedIdeal, renderedFlaw, renderedBond, renderedCharacteristic
;

  name = race = classification = background = alignment =
  level = exp = curHp = tempHp = maxHp = hitDice = speed = initiative = ac =
  strMod = strScore = dexMod = dexScore = conMod = conScore =
  intMod = intScore = wisMod = wisScore = chaMod = chaScore =
  profBonus = inspiration = bardicInspirations = //passPerc = passIns = passInv =
  '-';
  statStr = statDex = statCon = statInt = statWis = statCha =
  statAcr = statAni = statArc = statAth = statDec = statHis =
  statIns = statInti = statInv = statMed = statNat = statPerc =
  statPerf = statPers = statRel = statSle = statSte = statSur =
  '0';


  let cbStr, cbDex, cbCon, cbInt, cbWis, cbCha;
  cbStr = cbDex = cbCon = cbInt = cbWis = cbCha =
  <i className="fa-regular fa-circle"></i>;

  let cbAcr, cbAni, cbArc, cbAth, cbDec, cbHis,
      cbIns, cbInti,cbInv, cbMed, cbNat, cbPerc,
      cbPerf,cbPers,cbRel, cbSle, cbSte, cbSur;
  cbAcr = cbAni = cbArc = cbAth = cbDec = cbHis =
  cbIns = cbInti =cbInv = cbMed = cbNat = cbPerc =
  cbPerf =cbPers =cbRel = cbSle = cbSte = cbSur =
  <i className="fa-regular fa-circle"></i>;

  let statPassPerc, statPassIns, statPassInv,
      statStrTemp, statDexTemp, statConTemp, statIntTemp,
      statWisTemp, statChaTemp, statAcrTemp, statAniTemp,
      statArcTemp, statAthTemp,statDecTemp, statHisTemp,
      statInsTemp, statIntiTemp, statInvTemp, statMedTemp,
      statNatTemp, statPercTemp,statPerfTemp, statPersTemp,
      statRelTemp, statSleTemp,statSteTemp, statSurTemp;

  statPassPerc = statPassIns = statPassInv =
  statStrTemp = statDexTemp = statConTemp = statIntTemp =
  statWisTemp = statChaTemp = statAcrTemp = statAniTemp =
  statArcTemp = statAthTemp = statDecTemp = statHisTemp =
  statInsTemp = statIntiTemp = statInvTemp = statMedTemp =
  statNatTemp = statPercTemp = statPerfTemp = statPersTemp =
  statRelTemp = statSleTemp = statSteTemp = statSurTemp = 0;

  let strModTemp, dexModTemp, conModTemp,
      intModTemp, wisModTemp, chaModTemp;

  let char = props.character;
  if (char) {
    name = char.name;
    classification = char.classification.name;
    let variantText = char.is_variant_background ? ` (${char.background.variant_name})` : '';
    background = `${char.background.name}${variantText}`;
    if (char.volatility && char.alignment) {
      alignment = char.volatility + " " + char.alignment;
    }
    if (char.race) {
      race = char.race.name;
    } else if (char.archrace) {
      race = char.archrace.name;
    }
    level = char.level;
    exp = char.exp;
    strScore = char.str_score ? char.str_score.toString() : '';
    strModTemp = char.str_score ? Math.floor((char.str_score - 10) / 2) : -999;
    if (strModTemp < 0 && strModTemp > -999) {
      strMod = "- " + Math.abs(strModTemp).toString()
    } else if (strModTemp >= 0) {
      strMod = "+ " + strModTemp.toString();
    } else {
      strMod = '-';
    }
    dexScore = char.dex_score ? char.dex_score.toString() : '';
    dexModTemp = char.dex_score ? Math.floor((char.dex_score - 10) / 2) : -999;
    // ac = dexModTemp > -999 ? (dexModTemp + 10).toString() : '-';
    if (dexModTemp < 0 && dexModTemp > -999) {
      dexMod = "- " + Math.abs(dexModTemp).toString()
    } else if (dexModTemp >= 0) {
      dexMod = "+ " + dexModTemp.toString();
    } else {
      dexMod = '-';
    }
    initiative = dexMod;
    conScore = char.con_score ? char.con_score.toString() : '';
    conModTemp = char.con_score ? Math.floor((char.con_score - 10) / 2) : -999;
    if (conModTemp < 0 && conModTemp > -999) {
      conMod = "- " + Math.abs(conModTemp).toString()
    } else if (conModTemp >= 0) {
      conMod = "+ " + conModTemp.toString();
    } else {
      conMod = '-';
    }
    intScore = char.int_score ? char.int_score.toString() : '';
    intModTemp = char.int_score ? Math.floor((char.int_score - 10) / 2) : -999;
    if (intModTemp < 0 && intModTemp > -999) {
      intMod = "- " + Math.abs(intModTemp).toString()
    } else if (intModTemp >= 0) {
      intMod = "+ " + intModTemp.toString();
    } else {
      intMod = '-';
    }
    wisScore = char.wis_score ? char.wis_score.toString() : '';
    wisModTemp = char.wis_score ? Math.floor((char.wis_score - 10) / 2) : -999;
    if (wisModTemp < 0 && wisModTemp > -999) {
      wisMod = "- " + Math.abs(wisModTemp).toString()
    } else if (wisModTemp >= 0) {
      wisMod = "+ " + wisModTemp.toString();
    } else {
      wisMod = '-';
    }
    chaScore = char.cha_score ? char.cha_score.toString() : '';
    chaModTemp = char.cha_score ? Math.floor((char.cha_score - 10) / 2) : -999;
    if (chaModTemp < 0 && chaModTemp > -999) {
      chaMod = "- " + Math.abs(chaModTemp).toString()
    } else if (chaModTemp >= 0) {
      chaMod = "+ " + chaModTemp.toString();
    } else {
      chaMod = '-';
    }

    let tempProfBonus = char.proficiency_bonus ? char.proficiency_bonus : 0;
    profBonus = char.proficiency_bonus ? "+ " + char.proficiency_bonus.toString() : '0';
    inspiration = char.inspiration_dice ? char.inspiration_dice.toString() : '0';
    bardicInspirations = char.bardic_inspirations ? char.bardic_inspirations.toString() : '0';


    let rages, kiPoints, sorceryPoints, superiorityDice;
    let renderedRages, renderedKiPoints, renderedSorceryPoints, renderedSuperiorityDice;
    if (char.classification.id === 0) {
      rages = char.rage_points;
      renderedRages = <div
                        className="campaign-char-rendered-bonus-field-container clickable-hp-box"
                        onClick={((e) => props.handleHPClick(e, 'rages'))}
                      >
                        {props.openField === 'rages'
                          ? <div
                              className="campaign-char-inventory-input-wrapper"
                            >
                              <label className="campaign-char-currency-input-field">
                                <input
                                  className="campaign-char-currency-field"
                                  type='text'
                                  onChange={props.handleTypedValueChange}
                                  onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                                  value={props.typedValue}
                                  placeholder={rages}
                                />
                              </label>
                            </div>
                          : <div
                              className="campaign-char-hp-box-wrapper"
                            >
                              {rages}
                            </div>
                        }
                        <div className="campaign-char-hp-box subtext">
                          Rages
                        </div>
                      </div>
    } else if (char.classification.id === 4) {
      superiorityDice = char.superiority_dice;
      renderedSuperiorityDice = <div
                        className="campaign-char-rendered-bonus-field-container clickable-hp-box"
                        onClick={((e) => props.handleHPClick(e, 'superiorityDice'))}
                      >
                        {props.openField === 'superiorityDice'
                          ? <div
                              className="campaign-char-inventory-input-wrapper"
                            >
                              <label className="campaign-char-currency-input-field">
                                <input
                                  className="campaign-char-currency-field"
                                  type='text'
                                  onChange={props.handleTypedValueChange}
                                  onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                                  value={props.typedValue}
                                  placeholder={superiorityDice}
                                />
                              </label>
                            </div>
                          : <div
                              className="campaign-char-hp-box-wrapper"
                            >
                              {superiorityDice}
                            </div>
                        }
                        <div className="campaign-char-hp-box subtext">
                          Superiority Dice
                        </div>
                      </div>
    } else if (char.classification.id === 5) {
      kiPoints = char.ki_points
      renderedKiPoints = <div
                        className="campaign-char-rendered-bonus-field-container clickable-hp-box"
                        onClick={((e) => props.handleHPClick(e, 'kiPoints'))}
                      >
                        {props.openField === 'kiPoints'
                          ? <div
                              className="campaign-char-inventory-input-wrapper"
                            >
                              <label className="campaign-char-currency-input-field">
                                <input
                                  className="campaign-char-currency-field"
                                  type='text'
                                  onChange={props.handleTypedValueChange}
                                  onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                                  value={props.typedValue}
                                  placeholder={kiPoints}
                                />
                              </label>
                            </div>
                          : <div
                              className="campaign-char-hp-box-wrapper"
                            >
                              {kiPoints}
                            </div>
                        }
                        <div className="campaign-char-hp-box subtext">
                          Ki Points
                        </div>
                      </div>
    } else if (char.classification.id === 9) {
      sorceryPoints = char.sorcery_points
      renderedSorceryPoints = <div
                        className="campaign-char-rendered-bonus-field-container clickable-hp-box"
                        onClick={((e) => props.handleHPClick(e, 'sorceryPoints'))}
                      >
                        {props.openField === 'sorceryPoints'
                          ? <div
                              className="campaign-char-inventory-input-wrapper"
                            >
                              <label className="campaign-char-currency-input-field">
                                <input
                                  className="campaign-char-currency-field"
                                  type='text'
                                  onChange={props.handleTypedValueChange}
                                  onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                                  value={props.typedValue}
                                  placeholder={sorceryPoints}
                                />
                              </label>
                            </div>
                          : <div
                              className="campaign-char-hp-box-wrapper"
                            >
                              {sorceryPoints}
                            </div>
                        }
                        <div className="campaign-char-hp-box subtext">
                          Sorcery Points
                        </div>
                      </div>
    }
    if (char.personality) {
      renderedPersonality = <div
                              onClick={((e) => props.handleQualityClick(e, char.personality, 'Personality'))}
                              className="campaign-char-rendered-bonus-field-container clickable-hp-box"
                            >
                              Personality
                            </div>

    }
    if (char.flaw) {
      renderedFlaw = <div
                        onClick={((e) => props.handleQualityClick(e, char.flaw, 'Flaw'))}
                        className="campaign-char-rendered-bonus-field-container clickable-hp-box"
                      >
                        Flaw
                      </div>

    }
    if (char.ideal) {
      renderedIdeal =  <div
                        onClick={((e) => props.handleQualityClick(e, char.ideal, 'Ideal'))}
                        className="campaign-char-rendered-bonus-field-container clickable-hp-box"
                      >
                        Ideal
                      </div>

    }
    if (char.bond) {
      renderedBond =  <div
                        onClick={((e) => props.handleQualityClick(e, char.bond, 'Bond'))}
                        className="campaign-char-rendered-bonus-field-container clickable-hp-box"
                      >
                        Bond
                      </div>

    }
    if (char.characteristic) {
      renderedCharacteristic =  <div
                                  onClick={((e) => props.handleQualityClick(e, char.characteristic, 'Characteristic'))}
                                  className="campaign-char-rendered-bonus-field-container clickable-hp-box"
                                >
                                  Characteristic
                                </div>

    }

    renderedBonusFields = <div className="campaign-char-rendered-bonus-fields">
                            {renderedPersonality}
                            {renderedIdeal}
                            {renderedBond}
                            {renderedFlaw}
                            {renderedCharacteristic}
                            {renderedRages}
                            {renderedSuperiorityDice}
                            {renderedKiPoints}
                            {renderedSorceryPoints}
                          </div>

    curHp = char.current_hp ? char.current_hp : '0';
    tempHp = char.temp_hp ? char.temp_hp : '0';
    maxHp = char.max_hp ? char.max_hp + (conModTemp * level) : '0';
    hitDice = char.hit_dice ? char.hit_dice : '-';
    speed = char.speed ? char.speed : '-';

    char.saving_throw_proficiencies.forEach((prof) => {
      if (prof.name === 'Strength')     {
        cbStr = <i className="fa-solid fa-circle"></i>;
        statStrTemp = strModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Dexterity')    {
        cbDex = <i className="fa-solid fa-circle"></i>;
        statDexTemp = dexModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Constitution') {
        cbCon = <i className="fa-solid fa-circle"></i>;
        statConTemp = conModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Intelligence') {
        cbInt = <i className="fa-solid fa-circle"></i>;
        statIntTemp = intModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Wisdom')       {
        cbWis = <i className="fa-solid fa-circle"></i>;
        statWisTemp = wisModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Charisma')     {
        cbCha = <i className="fa-solid fa-circle"></i>;
        statChaTemp = chaModTemp > -999 ? +tempProfBonus : 0;
      }
    });
    char.skill_proficiencies.forEach((prof) => {
      if (prof.name === 'Acrobatics')       {
        cbAcr = <i className="fa-solid fa-circle"></i>;
        statAcrTemp = dexModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Animal Handling')  {
        cbAni = <i className="fa-solid fa-circle"></i>;
        statAniTemp = wisModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Arcana')           {
        cbArc = <i className="fa-solid fa-circle"></i>;
        statArcTemp = intModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Athletics')        {
        cbAth = <i className="fa-solid fa-circle"></i>;
        statAthTemp = strModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Deception')        {
        cbDec = <i className="fa-solid fa-circle"></i>;
        statDecTemp = chaModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'History')          {
        cbHis = <i className="fa-solid fa-circle"></i>;
        statHisTemp = intModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Insight')          {
        cbIns = <i className="fa-solid fa-circle"></i>;
        statInsTemp = wisModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Intimidation')     {
        cbInti = <i className="fa-solid fa-circle"></i>;
        statIntiTemp = chaModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Investigation')    {
        cbInv = <i className="fa-solid fa-circle"></i>;
        statInvTemp = intModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Medicine')         {
        cbMed = <i className="fa-solid fa-circle"></i>;
        statMedTemp = wisModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Nature')           {
        cbNat = <i className="fa-solid fa-circle"></i>;
        statNatTemp = intModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Perception')       {
        cbPerc = <i className="fa-solid fa-circle"></i>;
        statPercTemp = wisModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Performance')      {
        cbPerf = <i className="fa-solid fa-circle"></i>;
        statPerfTemp = chaModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Persuasion')       {
        cbPers = <i className="fa-solid fa-circle"></i>;
        statPersTemp = chaModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Religion')         {
        cbRel = <i className="fa-solid fa-circle"></i>;
        statRelTemp = intModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Sleight of Hand')  {
        cbSle = <i className="fa-solid fa-circle"></i>;
        statSleTemp = dexModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Stealth')          {
        cbSte = <i className="fa-solid fa-circle"></i>;
        statSteTemp = dexModTemp > -999 ? +tempProfBonus : 0;
      }
      if (prof.name === 'Survival')         {
        cbSur = <i className="fa-solid fa-circle"></i>;
        statSurTemp = wisModTemp > -999 ? +tempProfBonus : 0;
      }
    });
    let equippedBodyVal,
    // equippedLeftHandVal,
    equippedRightHandVal;
    let pickups = char.inventories[0].armor.concat(char.inventories[0].weapons);
    pickups.forEach((pickup) => {
      if (pickup.equipped_body) {
        equippedBodyVal = pickup.item;
      // } else if (pickup.equipped_main) {
      //   equippedLeftHandVal = pickup.item;
      } else if (pickup.equipped_2ndy) {
        equippedRightHandVal = pickup.item;
      }
    });

    let acFlatAdd =
      equippedRightHandVal &&
      equippedRightHandVal.type === 'Armor' &&
      equippedRightHandVal.ac_flat_add
      ? equippedRightHandVal.ac_base_bonus
      : 0;
    let bodyArmorBonus = 0;
    if (equippedBodyVal) {
      let base = equippedBodyVal.ac_base_bonus;
      let addedDex = 0;
      if (equippedBodyVal.ac_apply_dex) {
        if (equippedBodyVal.ac_max_dex_add === null) {
          addedDex = 0;
        } else if (equippedBodyVal.ac_max_dex_add === 0) {
          addedDex = Math.floor((props.character.dex_score - 10) / 2);
        } else if (equippedBodyVal.ac_max_dex_add >= 0) {
          addedDex = equippedBodyVal.ac_max_dex_add;
        }
      }
      bodyArmorBonus = base + addedDex;
    } else {
      bodyArmorBonus = Math.floor((props.character.dex_score - 10) / 2) + 10;
    }
    ac = bodyArmorBonus + acFlatAdd;
  }

  statStr = strModTemp > -999 ? strModTemp + statStrTemp : '-';
  statDex = dexModTemp > -999 ? dexModTemp + statDexTemp : '-';
  statCon = conModTemp > -999 ? conModTemp + statConTemp : '-';
  statInt = intModTemp > -999 ? intModTemp + statIntTemp : '-';
  statWis = wisModTemp > -999 ? wisModTemp + statWisTemp : '-';
  statCha = chaModTemp > -999 ? chaModTemp + statChaTemp : '-';

  statAcr = dexModTemp > -999 ? dexModTemp + statAcrTemp : '-';
  statAni = wisModTemp > -999 ? wisModTemp + statAniTemp : '-';
  statArc = intModTemp > -999 ? intModTemp + statArcTemp : '-';
  statAth = strModTemp > -999 ? strModTemp + statAthTemp : '-';
  statDec = chaModTemp > -999 ? chaModTemp + statDecTemp : '-';
  statHis = intModTemp > -999 ? intModTemp + statHisTemp : '-';
  statIns = wisModTemp > -999 ? wisModTemp + statInsTemp : '-';
  statInti = chaModTemp > -999 ? chaModTemp + statIntiTemp : '-';
  statInv = intModTemp > -999 ? intModTemp + statInvTemp : '-';
  statMed = wisModTemp > -999 ? wisModTemp + statMedTemp : '-';
  statNat = intModTemp > -999 ? intModTemp + statNatTemp : '-';
  statPerc = wisModTemp > -999 ? wisModTemp + statPercTemp : '-';
  statPerf = chaModTemp > -999 ? chaModTemp + statPerfTemp : '-';
  statPers = chaModTemp > -999 ? chaModTemp + statPersTemp : '-';
  statRel = intModTemp > -999 ? intModTemp + statRelTemp : '-';
  statSle = dexModTemp > -999 ? dexModTemp + statSleTemp : '-';
  statSte = dexModTemp > -999 ? dexModTemp + statSteTemp : '-';
  statSur = wisModTemp > -999 ? wisModTemp + statSurTemp : '-';

  statPassPerc = wisModTemp > -999 ? 10 + wisModTemp + statPercTemp : 10;
  statPassIns =  wisModTemp > -999 ? 10 + wisModTemp + statInsTemp : 10;
  statPassInv =  intModTemp > -999 ? 10 + intModTemp + statInvTemp : 10;


  if (Number.isInteger(statStr)) {
    if (statStr < 0) {statStr = "- " + Math.abs(statStr).toString();}
    else             {statStr = "+ " + statStr.toString();}
  }
  if (Number.isInteger(statDex)) {
    if (statDex < 0) {statDex = "- " + Math.abs(statDex).toString();}
    else             {statDex = "+ " + statDex.toString();}
  }
  if (Number.isInteger(statCon)) {
    if (statCon < 0) {statCon = "- " + Math.abs(statCon).toString();}
    else             {statCon = "+ " + statCon.toString();}
  }
  if (Number.isInteger(statInt)) {
    if (statInt < 0) {statInt = "- " + Math.abs(statInt).toString();}
    else             {statInt = "+ " + statInt.toString();}
  }
  if (Number.isInteger(statWis)) {
    if (statWis < 0) {statWis = "- " + Math.abs(statWis).toString();}
    else             {statWis = "+ " + statWis.toString();}
  }
  if (Number.isInteger(statCha)) {
    if (statCha < 0) {statCha = "- " + Math.abs(statCha).toString();}
    else             {statCha = "+ " + statCha.toString();}
  }
  if (Number.isInteger(statAcr)) {
    if (statAcr < 0) {statAcr = "- " + Math.abs(statAcr).toString();}
    else             {statAcr = "+ " + statAcr.toString();}
  }
  if (Number.isInteger(statAni)) {
    if (statAni < 0) {statAni = "- " + Math.abs(statAni).toString();}
    else             {statAni = "+ " + statAni.toString();}
  }
  if (Number.isInteger(statArc)) {
    if (statArc < 0) {statArc = "- " + Math.abs(statArc).toString();}
    else             {statArc = "+ " + statArc.toString();}
  }
  if (Number.isInteger(statAth)) {
    if (statAth < 0) {statAth = "- " + Math.abs(statAth).toString();}
    else             {statAth = "+ " + statAth.toString();}
  }
  if (Number.isInteger(statDec)) {
    if (statDec < 0) {statDec = "- " + Math.abs(statDec).toString();}
    else             {statDec = "+ " + statDec.toString();}
  }
  if (Number.isInteger(statHis)) {
    if (statHis < 0) {statHis = "- " + Math.abs(statHis).toString();}
    else             {statHis = "+ " + statHis.toString();}
  }
  if (Number.isInteger(statIns)) {
    if (statIns < 0) {statIns = "- " + Math.abs(statIns).toString();}
    else             {statIns = "+ " + statIns.toString();}
  }
  if (Number.isInteger(statInti)) {
    if (statInti < 0) {statInti = "- " + Math.abs(statInti).toString();}
    else              {statInti = "+ " + statInti.toString();}
  }
  if (Number.isInteger(statInv)) {
    if (statInv < 0) {statInv = "- " + Math.abs(statInv).toString();}
    else             {statInv = "+ " + statInv.toString();}
  }
  if (Number.isInteger(statMed)) {
    if (statMed < 0) {statMed = "- " + Math.abs(statMed).toString();}
    else             {statMed = "+ " + statMed.toString();}
  }
  if (Number.isInteger(statNat)) {
    if (statNat < 0) {statNat = "- " + Math.abs(statNat).toString();}
    else             {statNat = "+ " + statNat.toString();}
  }
  if (Number.isInteger(statPerc)) {
    if (statPerc < 0) {statPerc = "- " + Math.abs(statPerc).toString();}
    else              {statPerc = "+ " + statPerc.toString();}
  }
  if (Number.isInteger(statPerf)) {
    if (statPerf < 0) {statPerf = "- " + Math.abs(statPerf).toString();}
    else              {statPerf = "+ " + statPerf.toString();}
  }
  if (Number.isInteger(statPers)) {
    if (statPers < 0) {statPers = "- " + Math.abs(statPers).toString();}
    else              {statPers = "+ " + statPers.toString();}
  }
  if (Number.isInteger(statRel)) {
    if (statRel < 0) {statRel = "- " + Math.abs(statRel).toString();}
    else             {statRel = "+ " + statRel.toString();}
  }
  if (Number.isInteger(statSle)) {
    if (statSle < 0) {statSle = "- " + Math.abs(statSle).toString();}
    else             {statSle = "+ " + statSle.toString();}
  }
  if (Number.isInteger(statSte)) {
    if (statSte < 0) {statSte = "- " + Math.abs(statSte).toString();}
    else             {statSte = "+ " + statSte.toString();}
  }
  if (Number.isInteger(statSur)) {
    if (statSur < 0) {statSur = "- " + Math.abs(statSur).toString();}
    else             {statSur = "+ " + statSur.toString();}
  }

  let successes, failures;
  successes = <div className="death-save-successes fillable-char-field" onClick={props.handleSuccessClick}>
                <div className="death-save-success">
                  <i className={`${props.character && props.character.successes > 0 ? "fa-solid" : "fa-regular"}  fa-circle`}></i>
                </div>
                <div className="death-save-success">
                  <i className={`${props.character && props.character.successes > 1 ? "fa-solid" : "fa-regular"}  fa-circle`}></i>
                </div>
                <div className="death-save-success">
                  <i className={`${props.character && props.character.successes > 2 ? "fa-solid" : "fa-regular"}  fa-circle`}></i>
                </div>
              </div>
  failures = <div className="death-save-failures fillable-char-field" onClick={props.handleFailureClick}>
                <div className="death-save-failure">
                  <i className={`${props.character && props.character.failures > 0 ? "fa-solid" : "fa-regular"}  fa-circle`}></i>
                </div>
                <div className="death-save-failure">
                  <i className={`${props.character && props.character.failures > 1 ? "fa-solid" : "fa-regular"}  fa-circle`}></i>
                </div>
                <div className="death-save-failure">
                  <i className={`${props.character && props.character.failures > 2 ? "fa-solid" : "fa-regular"}  fa-circle`}></i>
                </div>
              </div>





  // let spellcastingDetails;
  // let characteristicsSection;






  // let personalityHeight = 'height-25-pct';
  // let idealHeader = '';
  // let flaw, bond, personality, ideal, characteristicSection;
  // let infoSymbol;
  // if (props.character) {
  //   infoSymbol = <i className="fa-solid fa-angle-right info-symbol"></i>;
  //   // circle-info
  //   if (props.character.characteristic) {
  //     personalityHeight = 'height-20-pct';
  //     characteristicSection = <div className={`campaign-char-main-stats-background-personality-container ${personalityHeight}`}>
  //                               <div className="campaign-char-main-stats-background-personality">
  //                                 <div className="campaign-char-main-stats-background-personality-text">
  //                                   Characteristic
  //                                 </div>
  //                                 <div className="info-symbol-container">
  //                                   {infoSymbol}
  //                                 </div>
  //                               </div>
  //                               <div className="campaign-char-main-stats-background-personality-description">
  //
  //                               </div>
  //                             </div>
  //     }
  //
  //     // flaw = props.character.flaw.desc;
  //     // bond = props.character.bond.desc;
  //     // personality = props.character.personality.desc;
  //     // ideal = props.character.ideal.desc;
  //     // idealHeader = `(${props.character.ideal.header_text})`;
  // }
  let backButton =
  // props.level === 'dm' ?
          <div
            onClick={((e) => props.handleGoBack(e))}
            className="campaign-char-go-back-button"
          >
            <i className="fa-solid fa-caret-left"></i>
          </div>
          // : null;

  let img = props.character && props.character.photo
    ? <img className="character-portrait-circle" src={props.character.photo} alt="character icon" />
    : null;

  let levelUpButton = props.character.creator_id === props.currentUser.id
  ? <div
      onClick={((e) => props.handleLevelUpClick(e))}
      className="campaign-char-add-item-button"
    >
      LEVEL UP
    </div>
  : null;

  return(
    <div className="campaign-char-main-stats-container">
      {backButton}
      <div className="campaign-char-main-stats-header">
        <div className="campaign-char-main-stats-header-sub-section">
          {img}
          <div className="campaign-char-main-stats-header-stat-name-container">
            <div className="campaign-char-main-stats-header-stat-name grand-text">{name}</div>
            <div className="campaign-char-main-stats-header-stat-name subtext">Character Name</div>
          </div>
        </div>
        <div className="campaign-char-main-stats-header-sub-section campaign-char-main-stats-header-sub-section-right">
          <div className="small-char-stat fillable-char-field maintext">{race}</div>
          <div className="small-char-stat fillable-char-field maintext">{classification}</div>
          <div className="small-char-stat fillable-char-field maintext">{background}</div>
          <div className="small-char-stat subtext">Race</div>
          <div className="small-char-stat subtext">Class</div>
          <div className="small-char-stat subtext">Background</div>
          <div className="small-char-stat fillable-char-field maintext">{alignment}</div>
          <div className="small-char-stat fillable-char-field maintext">{level}</div>
          <div className="small-char-stat fillable-char-field maintext">{exp}</div>
          <div className="small-char-stat subtext">Alignment</div>
          <div className="small-char-stat subtext">Level</div>
          <div className="small-char-stat subtext">Exp</div>
        </div>
      </div>
      <div className="campaign-char-main-stats-sub-section">
        <div className="campaign-char-main-stats-sub-section-box campaign-char-main-stats-abilities">
          <div className="campaign-char-ability-container">
            <div className="campaign-char-ability-wrapper">
              <div className="campaign-char-ability ability-title">Strength</div>
              <div className="campaign-char-ability grand-ability fillable-char-field">{strMod}</div>
              <div className="campaign-char-ability ability-score fillable-char-field subtext">{strScore}</div>
            </div>
          </div>
          <div className="campaign-char-ability-container">
            <div className="campaign-char-ability-wrapper">
              <div className="campaign-char-ability ability-title">Dexterity</div>
              <div className="campaign-char-ability grand-ability fillable-char-field ">{dexMod}</div>
              <div className="campaign-char-ability ability-score fillable-char-field subtext">{dexScore}</div>
            </div>
          </div>
          <div className="campaign-char-ability-container">
            <div className="campaign-char-ability-wrapper">
              <div className="campaign-char-ability ability-title">Constitution</div>
              <div className="campaign-char-ability grand-ability fillable-char-field ">{conMod}</div>
              <div className="campaign-char-ability ability-score fillable-char-field subtext">{conScore}</div>
            </div>
          </div>
          <div className="campaign-char-ability-container">
            <div className="campaign-char-ability-wrapper">
              <div className="campaign-char-ability ability-title">Intelligence</div>
              <div className="campaign-char-ability grand-ability fillable-char-field">{intMod}</div>
              <div className="campaign-char-ability ability-score fillable-char-field subtext">{intScore}</div>
            </div>
          </div>
          <div className="campaign-char-ability-container">
            <div className="campaign-char-ability-wrapper">
              <div className="campaign-char-ability ability-title">Widsom</div>
              <div className="campaign-char-ability grand-ability fillable-char-field">{wisMod}</div>
              <div className="campaign-char-ability ability-score fillable-char-field subtext">{wisScore}</div>
            </div>
          </div>
          <div className="campaign-char-ability-container">
            <div className="campaign-char-ability-wrapper">
              <div className="campaign-char-ability ability-title">Charisma</div>
              <div className="campaign-char-ability grand-ability fillable-char-field">{chaMod}</div>
              <div className="campaign-char-ability ability-score fillable-char-field subtext">{chaScore}</div>
            </div>
          </div>
        </div>
        <div className="campaign-char-main-stats-sub-section-box campaign-char-main-stats-prof-insp">
          <div className="campaign-char-hp-box-container">
            <div className="campaign-char-hp-box-wrapper">
              {profBonus}
            </div>
            <div className="campaign-char-hp-box subtext">
              Proficiency
            </div>
          </div>
          <div
            className="campaign-char-hp-box-container clickable-hp-box"
            onClick={((e) => props.handleHPClick(e, 'inspirationDice'))}
          >
            {props.openField === 'inspirationDice'
              ? <div
                  className="campaign-char-inventory-input-wrapper"
                >
                  <label className="campaign-char-currency-input-field">
                    <input
                      className="campaign-char-currency-field"
                      type='text'
                      onChange={props.handleTypedValueChange}
                      onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                      value={props.typedValue}
                      placeholder={inspiration}
                    />
                  </label>
                </div>
              : <div
                  className="campaign-char-hp-box-wrapper"
                >
                  {inspiration}
                </div>
            }
            <div className="campaign-char-hp-box subtext">
              Inspiration Dice
            </div>
          </div>
          <div
            className="campaign-char-hp-box-container clickable-hp-box"
            onClick={((e) => props.handleHPClick(e, 'bardicInspirations'))}
          >
            {props.openField === 'bardicInspirations'
              ? <div
                  className="campaign-char-inventory-input-wrapper"
                >
                  <label className="campaign-char-currency-input-field">
                    <input
                      className="campaign-char-currency-field"
                      type='text'
                      onChange={props.handleTypedValueChange}
                      onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                      value={props.typedValue}
                      placeholder={bardicInspirations}
                    />
                  </label>
                </div>
              : <div
                  className="campaign-char-hp-box-wrapper"
                >
                  {bardicInspirations}
                </div>
            }
            <div className="campaign-char-hp-box subtext">
              Bardic Inspirations
            </div>
          </div>
        </div>
        <div className="campaign-char-main-stats-sub-section-box campaign-char-main-stats-saving-throws">
          <div className="campaign-char-main-stats-saving-throws-container">
            <div className="campaign-char-saving-throws-header">Saving Throws</div>
            <div className="campaign-char-saving-throw-box fillable-char-field">{cbStr}</div>
            <div className="campaign-char-saving-throw">Strength</div>
            <div className="campaign-char-saving-throw-value fillable-char-field">{statStr}</div>
            <div className="campaign-char-saving-throw-box fillable-char-field">{cbDex}</div>
            <div className="campaign-char-saving-throw">Dexterity</div>
            <div className="campaign-char-saving-throw-value fillable-char-field">{statDex}</div>
            <div className="campaign-char-saving-throw-box fillable-char-field">{cbCon}</div>
            <div className="campaign-char-saving-throw">Constitution</div>
            <div className="campaign-char-saving-throw-value fillable-char-field">{statCon}</div>
            <div className="campaign-char-saving-throw-box fillable-char-field">{cbInt}</div>
            <div className="campaign-char-saving-throw">Intelligence</div>
            <div className="campaign-char-saving-throw-value fillable-char-field">{statInt}</div>
            <div className="campaign-char-saving-throw-box fillable-char-field">{cbWis}</div>
            <div className="campaign-char-saving-throw">Wisdom</div>
            <div className="campaign-char-saving-throw-value fillable-char-field">{statWis}</div>
            <div className="campaign-char-saving-throw-box fillable-char-field">{cbCha}</div>
            <div className="campaign-char-saving-throw">Charisma</div>
            <div className="campaign-char-saving-throw-value">{statCha}</div>
          </div>
        </div>
      </div>
      <div className="campaign-char-main-stats-sub-section campaign-char-main-stats-middle-section">
        <div className="campaign-char-main-stats-sub-section-box campaign-char-main-stats-passive-skills">
          <div className="campaign-char-passive-skill-header">Passive Skills</div>
          <div className="campaign-char-passive-skill-value maintext">{statPassPerc}</div>
          <div className="campaign-char-passive-skill-value maintext">{statPassIns}</div>
          <div className="campaign-char-passive-skill-value maintext">{statPassInv}</div>
          <div className="campaign-char-passive-skill subtext">Perception</div>
          <div className="campaign-char-passive-skill subtext">Insight</div>
          <div className="campaign-char-passive-skill subtext">Investigation</div>

        </div>
        <div className="campaign-char-main-stats-sub-section-box campaign-char-main-stats-skills">
          <div className="campaign-char-main-stats-skills-container">
            <div className="campaign-char-skills-header">Skills</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbAcr}</div>
            <div className="campaign-char-skill">Acrobatics</div>
            <div className="campaign-char-skill-value fillable-char-field">{statAcr}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbAni}</div>
            <div className="campaign-char-skill">Animal Handling</div>
            <div className="campaign-char-skill-value fillable-char-field">{statAni}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbArc}</div>
            <div className="campaign-char-skill">Arcana</div>
            <div className="campaign-char-skill-value fillable-char-field">{statArc}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbAth}</div>
            <div className="campaign-char-skill">Athletics</div>
            <div className="campaign-char-skill-value fillable-char-field">{statAth}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbDec}</div>
            <div className="campaign-char-skill">Deception</div>
            <div className="campaign-char-skill-value fillable-char-field">{statDec}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbHis}</div>
            <div className="campaign-char-skill">History</div>
            <div className="campaign-char-skill-value fillable-char-field">{statHis}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbIns}</div>
            <div className="campaign-char-skill">Insight</div>
            <div className="campaign-char-skill-value fillable-char-field">{statIns}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbInti}</div>
            <div className="campaign-char-skill">Intimidation</div>
            <div className="campaign-char-skill-value fillable-char-field">{statInti}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbInv}</div>
            <div className="campaign-char-skill">Investigation</div>
            <div className="campaign-char-skill-value fillable-char-field">{statInv}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbMed}</div>
            <div className="campaign-char-skill">Medicine</div>
            <div className="campaign-char-skill-value fillable-char-field">{statMed}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbNat}</div>
            <div className="campaign-char-skill">Nature</div>
            <div className="campaign-char-skill-value fillable-char-field">{statNat}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbPerc}</div>
            <div className="campaign-char-skill">Perception</div>
            <div className="campaign-char-skill-value fillable-char-field">{statPerc}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbPerf}</div>
            <div className="campaign-char-skill">Performance</div>
            <div className="campaign-char-skill-value fillable-char-field">{statPerf}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbPers}</div>
            <div className="campaign-char-skill">Persuasion</div>
            <div className="campaign-char-skill-value fillable-char-field">{statPers}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbRel}</div>
            <div className="campaign-char-skill">Religion</div>
            <div className="campaign-char-skill-value fillable-char-field">{statRel}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbSle}</div>
            <div className="campaign-char-skill">Sleight of Hand</div>
            <div className="campaign-char-skill-value fillable-char-field">{statSle}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbSte}</div>
            <div className="campaign-char-skill">Stealth</div>
            <div className="campaign-char-skill-value fillable-char-field">{statSte}</div>
            <div className="campaign-char-skill-box fillable-char-field">{cbSur}</div>
            <div className="campaign-char-skill">Survival</div>
            <div className="campaign-char-skill-value fillable-char-field">{statSur}</div>
          </div>
        </div>
      </div>
      <div className="campaign-char-main-stats-sub-section">
        {levelUpButton}
        <div className="campaign-char-main-stats-ac-init-speed-box">
          <div className="campaign-char-main-stats-ac-init-speed-stat fillable-char-field maintext">{ac}</div>
          <div className="campaign-char-main-stats-ac-init-speed-stat fillable-char-field maintext">{initiative}</div>
          <div className="campaign-char-main-stats-ac-init-speed-stat fillable-char-field maintext">{speed}</div>
          <div className="campaign-char-main-stats-ac-init-speed-stat ac-init-speed-text subtext">Armor Class</div>
          <div className="campaign-char-main-stats-ac-init-speed-stat ac-init-speed-text subtext">Initiative</div>
          <div className="campaign-char-main-stats-ac-init-speed-stat ac-init-speed-text subtext">Speed</div>
        </div>
        <div className="campaign-char-main-stats-hp-box">
          <div
            className="campaign-char-hp-box-container clickable-hp-box"
            onClick={((e) => props.handleHPClick(e, 'curHP'))}
          >
            {props.openField === 'curHP'
              ? <div
                  className="campaign-char-inventory-input-wrapper"
                >
                  <label className="campaign-char-currency-input-field">
                    <input
                      className="campaign-char-currency-field"
                      type='text'
                      onChange={props.handleTypedValueChange}
                      onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                      value={props.typedValue}
                      placeholder={curHp}
                    />
                  </label>
                </div>
              : <div
                  className="campaign-char-hp-box-wrapper"
                >
                  {curHp}
                </div>
            }
            <div className="campaign-char-hp-box subtext">
              Current HP
            </div>
          </div>
          <div className="campaign-char-hp-box-container">
            <div className="campaign-char-hp-box-wrapper">
              {maxHp}
            </div>
            <div className="campaign-char-hp-box subtext">
              Maximum HP
            </div>
          </div>
          <div
            className="campaign-char-hp-box-container clickable-hp-box"
            onClick={((e) => props.handleHPClick(e, 'tempHP'))}
          >
            {props.openField === 'tempHP'
              ? <div
                  className="campaign-char-inventory-input-wrapper"
                >
                  <label className="campaign-char-currency-input-field">
                    <input
                      className="campaign-char-currency-field"
                      type='text'
                      onChange={props.handleTypedValueChange}
                      onKeyPress={event => {if (event.key === 'Enter') {return props.handleKeyPress(event)}}}
                      value={props.typedValue}
                      placeholder={tempHp}
                    />
                  </label>
                </div>
              : <div
                  className="campaign-char-hp-box-wrapper"
                >
                  {tempHp}
                </div>
            }
            <div className="campaign-char-hp-box subtext">
              Temporary HP
            </div>
          </div>
        </div>
        <div className="campaign-char-main-stats-hit-dice-death-saves-box">
          <div className="campaign-char-main-stats-hit-dice-container">
            <div className="campaign-char-main-stats-hit-dice-stat fillable-char-field maintext">{hitDice}</div>
            <div className="campaign-char-main-stats-hit-dice-stat hit-dice-stat-text subtext">Hit Dice</div>
          </div>
          <div className="campaign-char-main-stats-death-saves-container">
            <div className="campaign-char-main-stats-death-saves-chart">
              <div className="campaign-char-main-stats-death-saves-chart-set">
                <div className="death-save-successes-text subtext">Successes</div>
                {successes}
              </div>
              <div className="campaign-char-main-stats-death-saves-chart-set">
                <div className="death-save-failures-text subtext">Failures</div>
                {failures}
              </div>
            </div>
            <div className="campaign-char-main-stats-death-saves-text subtext">Death Saves</div>
          </div>
          <div className="campaign-char-main-stats-combat-tooltip-button-container">
            <div className="campaign-char-main-stats-combat-tooltip-button" onClick={((e) => props.handleToolTipClick(e))}>
              Combat Tips
            </div>
          </div>
        </div>
        <div className="campaign-char-main-stats-background-bio">
          <div className="campaign-char-bonus-fields-header">
            Other Fields
          </div>
          {renderedBonusFields}
        </div>
      </div>
    </div>
  )
}

export default CampaignCharSheetMainStats

//
//
// <div className={`campaign-char-main-stats-background-personality-container ${personalityHeight}`}>
//   <div className="campaign-char-main-stats-background-personality">
//     <div className="campaign-char-main-stats-background-personality-text">
//       Personality
//     </div>
//     <div className="info-symbol-container">
//       {infoSymbol}
//     </div>
//   </div>
//   <div className="campaign-char-main-stats-background-personality-description">
//     {personality}
//   </div>
// </div>
// <div className={`campaign-char-main-stats-background-personality-container ${personalityHeight}`}>
//   <div className="campaign-char-main-stats-background-personality">
//     <div className="campaign-char-main-stats-background-personality-text">
//       Flaw
//     </div>
//     <div className="info-symbol-container">
//       {infoSymbol}
//     </div>
//   </div>
//   <div className="campaign-char-main-stats-background-personality-description">
//     {flaw}
//   </div>
// </div>
// <div className={`campaign-char-main-stats-background-personality-container ${personalityHeight}`}>
//   <div className="campaign-char-main-stats-background-personality">
//     <div className="campaign-char-main-stats-background-personality-text">
//       {`Ideal ${idealHeader}`}
//     </div>
//     <div className="info-symbol-container">
//       {infoSymbol}
//     </div>
//   </div>
//   <div className="campaign-char-main-stats-background-personality-description">
//     {ideal}
//   </div>
// </div>
// <div className={`campaign-char-main-stats-background-personality-container ${personalityHeight}`}>
//   <div className="campaign-char-main-stats-background-personality">
//     <div className="campaign-char-main-stats-background-personality-text">
//       Bond
//     </div>
//     <div className="info-symbol-container">
//       {infoSymbol}
//     </div>
//   </div>
//   <div className="campaign-char-main-stats-background-personality-description">
//     {bond}
//   </div>
// </div>
// {characteristicSection}
