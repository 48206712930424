import React from 'react';
// import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';

const CampaignForm = props => {

// class CampaignForm extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       name: "",
//       desc: ""
//     };
//     this.handleFormSubmit = this.handleFormSubmit.bind(this);
//     this.handleNameChange = this.handleNameChange.bind(this);
//     this.handleDescChange = this.handleDescChange.bind(this);
//   }
//
  // handleNameChange(event) {
  //   this.setState({ name: event.target.value });
  // }
  //
  // handleDescChange(event) {
  //   this.setState({ desc: event.target.value });
  // }
  //
  // async handleFormSubmit(event) {
  //   event.preventDefault();
  //   let formPayload = new FormData();
  //   formPayload.append('name', this.state.name);
  //   formPayload.append('desc', this.state.desc);
  //   const response = await fetch("/api/v1/campaigns", {
  //     method: 'POST',
  //     body: formPayload,
  //     credentials: 'same-origin'
  //   });
  //   const json = await response.json();
  //   this.props.pop(json.msg, json.status);
  //   if(response.status === 200) {
  //     // this.props.history.push("/campaigns/"+res.body.id);
  //     window.location.assign("/campaigns/"+json.id);
  //   } else {
  //     this.props.history.push("/campaigns");
  //   }
  // }


  // render() {
  let disabled = !props.name ? 'disabled-button' : null;

  return(
    <div className="pop-up">
      <div className="campaigns-create-popup">
        <div className="campaigns-create-popup-headings-container">
          <div className="campaigns-create-popup-main-heading">
            Create a Campaign
          </div>
          <div className="campaigns-create-popup-sub-heading">
            (You will become the DM)
          </div>
        </div>
        <form className="campaigns-create-popup-inner">
          <label className="campaigns-create-popup-field">
            <input className="field" type='text' onChange={props.handleNameChange} value={props.name} placeholder={'Campaign Name'}/>
          </label>
          <label className="campaigns-create-popup-field">
            <input className="field" type='textarea' onChange={props.handleDescChange} value={props.desc} placeholder={'Quick Description'}/>
          </label>
          <div className="campaigns-create-popup-button-container">
            <div className={`pop-up-button pop-up-yes ${disabled}`} onClick={props.handleFormSubmit}>Submit</div>
            <div className="pop-up-button pop-up-no" onClick={props.closePopUp}>Cancel</div>
          </div>
        </form>
      </div>
    </div>
  )
  // }
}

export default CampaignForm
// export default withRouter(CampaignForm)
